import React from "react";

import { formatDate } from "../../utils/date";
import { RoomBookingStatuses } from "../../enums/RoomBookings/RoomBookingStatuses";
import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import { IRoomBookingsItem } from "../../models/RoomBookings/Interfaces/IRoomBookingsItem";
import { String_Format } from "../../utils/utils";

interface IProps {
  row: IRoomBookingsItem;
}

const ExpandedContent: React.FC<IProps> = ({ row }) => {
  return (
    <div>
      <div className="span-details-row">
        <div>
          {RoomBookingsGrid.Resources.fullName}{" "}
          <span>
            {row.userFirstName} {row.userLastName} |{" "}
            {String_Format(RoomBookingsGrid.Resources.id, row?.userId)}
          </span>
        </div>
      </div>

      <div className="span-details-row">
        {row.statusId == RoomBookingStatuses.Cancelled ? (
          <div className="span-details-row">
            {RoomBookingsGrid.Resources.cancelledAt}
            <span className="timestamp">{formatDate(row.cancelledAt)}</span>
          </div>
        ) : null}
        {row.statusId == RoomBookingStatuses.Expired ? (
          <div className="span-details-row">
            {RoomBookingsGrid.Resources.expiredAt}
            <span className="timestamp">{formatDate(row.expiresAt)}</span>
          </div>
        ) : null}
        {row.statusId == RoomBookingStatuses.Rejected ? (
          <div className="span-details-row">
            {RoomBookingsGrid.Resources.rejectedAt}
            <span className="timestamp">{formatDate(row.rejectedAt)}</span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ExpandedContent;
