import { ColumnDef } from "@tanstack/react-table";

import { IRoomBookingsItem } from "../../models/RoomBookings/Interfaces/IRoomBookingsItem";
import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import { firstLetterToLowerCase, String_Format } from "../../utils/utils";
import { formatDateInZone, isSameDay, DATE_FORMAT } from "../../utils/date";
import { RoomBookingTypes } from "../../enums/RoomBookings/RoomBookingTypes";
import RoomBookingTypeList from "../../resources/AdminUI/RoomBookings/RoomBookingTypeList";

const buildBookingInfoCell = (row: any) => {
  const { bookingTypeId, startsAt, endsAt, timezone, bookingAmount } =
    row.original;
  const type = RoomBookingTypes[bookingTypeId];
  const typeName = RoomBookingTypeList.Resources[firstLetterToLowerCase(type)];

  switch (bookingTypeId) {
    case RoomBookingTypes.Hourly:
      return (
        <div className="column-xlarge">
          <p>{typeName}</p>
          <p className="timestamp">
            {formatDateInZone(startsAt, timezone)} -{" "}
            {formatDateInZone(endsAt, timezone)}
            <span className="timezone"> {timezone}</span>
          </p>
        </div>
      );
    case RoomBookingTypes.Daily:
      return (
        <div className="column-xlarge">
          <p>
            {String_Format(
              RoomBookingsGrid.Resources.bookingAmount,
              typeName,
              bookingAmount
            )}
          </p>
          <p className="timestamp">
            {isSameDay(startsAt, endsAt)
              ? formatDateInZone(startsAt, timezone, DATE_FORMAT)
              : formatDateInZone(startsAt, timezone, DATE_FORMAT) +
                " - " +
                formatDateInZone(endsAt, timezone, DATE_FORMAT)}
            <span className="timezone"> {timezone}</span>
          </p>
        </div>
      );
    case RoomBookingTypes.Monthly:
      return (
        <div className="column-xlarge">
          <p>
            {String_Format(
              RoomBookingsGrid.Resources.bookingAmount,
              typeName,
              bookingAmount * 30
            )}
          </p>
          <p className="timestamp">
            {formatDateInZone(startsAt, timezone, DATE_FORMAT)} -{" "}
            {formatDateInZone(endsAt, timezone, DATE_FORMAT)}
            <span className="timezone"> {timezone}</span>
          </p>
        </div>
      );
  }
};

// Refactor to accept a parameter to show or hide the actions column
export const getRoomBookingsColumns = (
  showActionsColumn: boolean
): ColumnDef<IRoomBookingsItem>[] => {
  const columns: ColumnDef<IRoomBookingsItem>[] = [
    {
      header: "VISIT ID",
      cell: ({ row }) => (
        <div>
          <p>{String_Format(RoomBookingsGrid.Resources.id, row.original.id)}</p>
        </div>
      ),
    },

    {
      header: "SPACE",
      cell: ({ row }) => (
        <div>
          <p className="room-name">{row.original.workspaceName}</p>
          <p>
            {String_Format(
              RoomBookingsGrid.Resources.spaceId,
              row.original.workspaceId
            )}
          </p>
        </div>
      ),
    },
    {
      header: "ROOM",
      cell: ({ row }) => (
        <div>
          <p className="room-name">{row.original.roomName}</p>
          <p>
            {String_Format(
              RoomBookingsGrid.Resources.roomId,
              row.original.roomId
            )}
          </p>
        </div>
      ),
    },
    {
      header: "BOOKING INFO",
      cell: ({ row }) => buildBookingInfoCell(row),
    },

    {
      header: "STATUS",
      cell: ({ row }) => (
        <div>
          <p>{row.original.statusName}</p>
        </div>
      ),
    },
  ];

  return columns;
};
