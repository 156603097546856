// PendingRoomRequestsContext.tsx
import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useMemo,
} from "react";
import { IRoomBookingsItem } from "../models/RoomBookings/Interfaces/IRoomBookingsItem";
import IPageIndex from "../models/Pagination/IPageIndex";
import { DefaultFilterValues } from "../enums/Common/DefaultFilterValues";

export interface PendingBookingsContextType {
  loading: boolean;
  itemsPerPage: number;
  currentPage: number;
  firstIndexFromPage: number;
  lastIndexFromPage: number;
  pageIndexArray: IPageIndex[];
  filters: string;
  roomBookings: IRoomBookingsItem[];
  numberOfRoomBookings: number;
  setLoading: (loading: boolean) => void;
  setItemsPerPage: (itemsPerPage: number) => void;
  setCurrentPage: (currentPage: number) => void;
  setFilters: (filters: string) => void;
  setRoomBookings: (roomBookings: IRoomBookingsItem[]) => void;
  setNumberOfRoomBookings: (numberOfRoomBookings: number) => void;
  setPageIndexArray: (pageIndexArray: IPageIndex[]) => void;
  setFirstIndexFromPage: (firstIndexFromPage: number) => void;
  setLastIndexFromPage: (lastIndexFromPage: number) => void;
}

const PendingBookingsContext = createContext<
  PendingBookingsContextType | undefined
>(undefined);

export const PendingBookingsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [loading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [firstIndexFromPage, setFirstIndexFromPage] = useState(1);
  const [lastIndexFromPage, setLastIndexFromPage] = useState(1);
  const [pageIndexArray, setPageIndexArray] = useState<IPageIndex[]>([]);
  const [filters, setFilters] = useState("");
  const [roomBookings, setRoomBookings] = useState<IRoomBookingsItem[]>([]);
  const [numberOfRoomBookings, setNumberOfRoomBookings] = useState(0);

  const value = useMemo(
    () => ({
      loading,
      itemsPerPage,
      currentPage,
      firstIndexFromPage,
      lastIndexFromPage,
      pageIndexArray,
      filters,
      roomBookings,
      numberOfRoomBookings,
      setLoading,
      setItemsPerPage,
      setCurrentPage,
      setFilters,
      setRoomBookings,
      setNumberOfRoomBookings,
      setPageIndexArray,
      setFirstIndexFromPage,
      setLastIndexFromPage,
    }),
    [
      loading,
      itemsPerPage,
      currentPage,
      firstIndexFromPage,
      lastIndexFromPage,
      pageIndexArray,
      filters,
      roomBookings,
      numberOfRoomBookings,
    ]
  );

  return (
    <PendingBookingsContext.Provider value={value}>
      {children}
    </PendingBookingsContext.Provider>
  );
};

export const usePendingBookingsContext = () => {
  const context = useContext(PendingBookingsContext);
  if (context === undefined) {
    throw new Error(
      "usePendingRoomRequests must be used within a PendingRoomRequestsProvider"
    );
  }
  return context;
};
