import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
import { FC, useMemo } from "react";

import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import General from "../../resources/AdminUI/General";
import { DefaultFilterValues } from "../../enums/Common/DefaultFilterValues";

const { RangePicker } = DatePicker;

interface IFiltersSectionProps {
  workspaces: any[];
  singleSelectedWorkspace: string;
  singleSelectedStatus: string;
  searchTerm: string;
  startDateSearchTemplate: any;
  endDateSearchTemplate: any;
  dateFormat: string;
  onSearchFilterChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onWorkspaceFilterChange: (value: string) => void;
  setSingleSelectedStatus: (value: string) => void;
  onDateChange: (dates: [dayjs.Dayjs | null, dayjs.Dayjs | null]) => void;
  getAllRoomStatuses: () => any[];
}

const FiltersSection: FC<IFiltersSectionProps> = ({
  workspaces,
  singleSelectedWorkspace,
  singleSelectedStatus,
  searchTerm,
  startDateSearchTemplate,
  endDateSearchTemplate,
  dateFormat,
  onSearchFilterChange,
  onDateChange,
  onWorkspaceFilterChange,
  getAllRoomStatuses,
  setSingleSelectedStatus,
}) => {
  const workspacesWithAllOption = useMemo(() => {
    if (!workspaces) {
      return [];
    }
    let newWorkspaces = [
      {
        label: General.Resources.allWorkspaces,
        value: DefaultFilterValues.All.toString(),
        group: DefaultFilterValues.All.toString(),
      },
      ...workspaces,
    ];

    return newWorkspaces;
  }, [workspaces]);

  return (
    <div className="filter-bar">
      <div className="filter-search">
        <div className="input-group">
          <span className="icon material-symbols-outlined">search</span>
          <input
            type="text"
            className="form-control"
            placeholder={RoomBookingsGrid.Resources.searchByLogIdOrUserName}
            onChange={onSearchFilterChange}
            name="searchTerm"
            value={searchTerm}
          />
        </div>
      </div>

      <div className="filter-item">
        <div className="input-group">
          <Select
            options={workspacesWithAllOption}
            onChange={onWorkspaceFilterChange}
            value={singleSelectedWorkspace}
            showSearch
            optionFilterProp="label"
          />
        </div>
      </div>

      <div className="filter-item">
        <div className="input-group">
          <Select
            options={getAllRoomStatuses()}
            onChange={setSingleSelectedStatus}
            value={singleSelectedStatus}
          ></Select>
        </div>
      </div>

      <RangePicker
        value={[
          startDateSearchTemplate
            ? dayjs.unix(startDateSearchTemplate)
            : startDateSearchTemplate,
          endDateSearchTemplate
            ? dayjs.unix(endDateSearchTemplate)
            : endDateSearchTemplate,
        ]}
        format={dateFormat}
        onChange={onDateChange}
      />
    </div>
  );
};

export default FiltersSection;
