import React, { useCallback, useState } from "react";
import Helmet from "react-helmet";
import { useQueryClient } from "@tanstack/react-query";
import { Modal } from "antd";

import { firstLetterToLowerCase } from "../../utils/utils";
import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import { RoomBookingStatuses } from "../../enums/RoomBookings/RoomBookingStatuses";
import ExpandedContent from "../../components/RoomBookings/ExpandedContent";
import { RoomBookingRejectReasons } from "../../enums/RoomBookings/RoomBookingRejectReasons";
import { usePendingRoomsRequestsPage } from "../../hooks/RoomBookings/usePendingRoomsRequestsPage";
import { useRoomBookingsQueries } from "../../hooks/RoomBookings/useRoomBookingsQueries";
import { IRoomBookingsItem } from "../../models/RoomBookings/Interfaces/IRoomBookingsItem";
import { RoomBookingsItem } from "../../models/RoomBookings/RoomBookingsItem";
import General from "../../resources/AdminUI/General";
import RoomBookingRejectReasonList from "../../resources/AdminUI/RoomBookings/RoomBookingRejectReasonList";
import ErrorMessages from "../../resources/Common/ErrorMessages";
import { getPendingRoomBookingsColumns } from "../../components/RoomBookings/PendingRoomBookingsTableColumns";
import TanstackTable from "../../components/Shared/TanstackTable";
import PaginationBar from "../../components/Shared/PaginationBar";
import { usePendingBookingsContext } from "../../providers/PendingBookingsProvider";

const { confirm } = Modal;

const PendingRoomRequestsPage = () => {
  const pendingStatus = RoomBookingStatuses.Pending.toString();

  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState<boolean>(false);
  const [selectedRoomBooking, setSelectedRoomBooking] = useState<
    RoomBookingsItem | undefined
  >(undefined);

  const queryClient = useQueryClient();
  const {
    itemsPerPage,
    numberOfRoomBookings,
    lastIndexFromPage,
    firstIndexFromPage,
    pageIndexArray,
  } = usePendingBookingsContext();
  const { changeCurrentPage, reloadRoomBookingsData, filters, skip } =
    usePendingRoomsRequestsPage(pendingStatus);
  const { acceptRoomBooking: acceptRoomBookingRequest, getRoomBookings } =
    useRoomBookingsQueries();

  const { data: roomBookingsData } = getRoomBookings(
    itemsPerPage,
    skip,
    filters
  );
  const { mutate: acceptRoomBookingMutate } = acceptRoomBookingRequest();

  const hideDeclineRoomBookingModal = useCallback(() => {
    setIsDeclineModalOpen(false);
  }, []);

  const showDeclineRoomBookingModal = useCallback(
    (booking: RoomBookingsItem) => {
      setIsDeclineModalOpen(true);
      setSelectedRoomBooking(booking);
    },
    []
  );

  const getDeclineReasonsList = useCallback(() => {
    const options = Object.keys(RoomBookingRejectReasons)
      .filter((key) => typeof RoomBookingRejectReasons[key as any] === "number")
      .map((key) => ({
        value: RoomBookingRejectReasons[key as any].toString(),
        label:
          RoomBookingRejectReasonList.Resources[firstLetterToLowerCase(key)],
      }));
    return options;
  }, []);

  // Call the acceptRoomBooking mutation here
  const acceptRoomBooking = useCallback(
    (id: number) => {
      acceptRoomBookingMutate(id, {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["roomBookings"] });
        },
        onError: () => {
          Modal.error({
            title: ErrorMessages.Resources.generalErrorMessage,
          });
        },
      });
    },
    [acceptRoomBookingMutate]
  );

  // Use acceptRoomBooking mutation here instead of direct service call
  const showAcceptRoomBookingModal = useCallback(
    (id: number) => {
      confirm({
        title: RoomBookingsGrid.Resources.acceptRoomRequest,
        content: (
          <div>
            <p>
              {RoomBookingsGrid.Resources.acceptRoomRequestConfirmationMessage}
            </p>
          </div>
        ),
        okText: RoomBookingsGrid.Resources.accept,
        okType: "primary",
        cancelText: General.Resources.cancelButtonLabel,
        onOk: () => acceptRoomBooking(id),
      });
    },
    [acceptRoomBooking]
  );

  const expandedContent = (row: IRoomBookingsItem) => (
    <ExpandedContent row={row} />
  );

  const pendingRoomBookingColumns = getPendingRoomBookingsColumns({
    isDeclineModalOpen,
    selectedRoomBooking,
    showDeclineModal: showDeclineRoomBookingModal,
    hideDeclineModal: hideDeclineRoomBookingModal,
    getDeclineReasonsList,
    showAcceptRoomBookingModal,
  });

  return (
    <div className="space-list-page">
      <Helmet title={RoomBookingsGrid.Resources.pendingRoomsRequest} />

      <div className="page-top-container">
        <div className="page-title">
          {RoomBookingsGrid.Resources.pendingRoomsRequest}
        </div>
      </div>

      <div>
        {roomBookingsData && roomBookingsData?.entities?.length > 0 && (
          <TanstackTable
            data={roomBookingsData?.entities}
            columns={pendingRoomBookingColumns}
            pageCount={Math.ceil(
              roomBookingsData.numberOfEntities / itemsPerPage
            )}
            expandedContent={expandedContent}
          />
        )}
        {roomBookingsData && roomBookingsData?.entities?.length === 0 && (
          <div className="no-elements">{General.Resources.noElements}</div>
        )}
        <PaginationBar
          totalItems={numberOfRoomBookings}
          reload={reloadRoomBookingsData}
          changeCurrentPage={changeCurrentPage}
          firstIndexFromPage={firstIndexFromPage}
          lastIndexFromPage={lastIndexFromPage}
          pageIndexArray={pageIndexArray}
          maxPagesDisplayed={3}
          pageNeighbours={1}
        />{" "}
      </div>
    </div>
  );
};

export default PendingRoomRequestsPage;
