// RoomBookingsGrid.tsx
import React, { FC } from "react";

import { IRoomBookingsItem } from "../../models/RoomBookings/Interfaces/IRoomBookingsItem";
import General from "../../resources/AdminUI/General";
import Loading from "../Shared/Loading";
import PaginationBar from "../Shared/PaginationBar";
import TanstackTable from "../Shared/TanstackTable";
import { useRoomBookingsContext } from "../../providers/RoomBookingsProvider";
import { useRoomBookingsPage } from "../../hooks/RoomBookings/useRoomBookingsPage";
import { useRoomBookingsQueries } from "../../hooks/RoomBookings/useRoomBookingsQueries";
import ExpandedContent from "./ExpandedContent";

interface IProps {
  roomBookingsColumns: any;
}

const RoomBookingsGridWrapper: FC<IProps> = ({ roomBookingsColumns }) => {
  const {
    itemsPerPage,
    numberOfRoomBookings,
    lastIndexFromPage,
    firstIndexFromPage,
    pageIndexArray,
  } = useRoomBookingsContext();

  const { skip, filters, changeCurrentPage, reloadRoomBookingsData } =
    useRoomBookingsPage();

  const { getRoomBookings } = useRoomBookingsQueries();
  const { data, error, isLoading } = getRoomBookings(
    itemsPerPage,
    skip,
    filters
  );

  if (isLoading || !data) {
    return <Loading />;
  }

  const expandedContent = (row: IRoomBookingsItem) => (
    <ExpandedContent row={row} />
  );

  return (
    <div>
      {data && data?.entities?.length > 0 && (
        <TanstackTable
          data={data?.entities}
          columns={roomBookingsColumns}
          pageCount={Math.ceil(data.numberOfEntities / itemsPerPage)}
          expandedContent={expandedContent}
        />
      )}
      {data && data?.entities?.length === 0 && (
        <div className="no-elements">{General.Resources.noElements}</div>
      )}
      <PaginationBar
        totalItems={numberOfRoomBookings}
        reload={reloadRoomBookingsData}
        changeCurrentPage={changeCurrentPage}
        firstIndexFromPage={firstIndexFromPage}
        lastIndexFromPage={lastIndexFromPage}
        pageIndexArray={pageIndexArray}
        maxPagesDisplayed={3}
        pageNeighbours={1}
      />{" "}
    </div>
  );
};

export default RoomBookingsGridWrapper;
