import { useState, useCallback } from "react";
import { notification } from "antd";

import Result from "../models/Common/Result";
import { ApplicationPaths } from "../enums/Common/ApplicationPaths";
import { PayloadTypes } from "../enums/Common/PayloadTypes";
import { NotificationStore } from "../store/NotificationStore";
import { LoginStore } from "../store/LoginStore";
import Globals from "../Globals";
import { store } from "..";

interface IRequestOptions {
  url: string;
  data?: any;
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
  onError?: (error: any) => any;
  responseType?: "ANY" | "STRING" | "BLOB" | "ARRAYBUFFER" | "FORMDATA";
}

interface ISendFormDataOptions {
  url: string;
  data: FormData | any;
  method: "POST" | "PUT" | "PATCH" | "DELETE";
  responseType?: "ANY" | "STRING" | "BLOB" | "ARRAYBUFFER" | "FORMDATA";
}

let refreshTokenPromise: Promise<any> | null = null;

export const useServiceBase = () => {
  const [loading, setLoading] = useState(false);

  const executeAuthenticatedRequest = useCallback(
    async (url: string, options?: RequestInit): Promise<Response> => {
      let initPromise: Promise<void> = refreshTokenPromise || Promise.resolve();
      const isTokenExpired =
        store.getState().login.expiresAt * 1000 < new Date().getTime();

      if (isTokenExpired && refreshTokenPromise == null) {
        initPromise = LoginStore.boundActions.refreshToken().then(() => {
          refreshTokenPromise = null;
        });
        refreshTokenPromise = initPromise;
      }

      return initPromise.then(() => {
        options = options || {};
        options.headers = {
          ...(options.headers || {}),
          "Accept-Language": Globals.currentActiveCulture,
          Authorization: `Bearer ${store.getState().login.accessToken}`,
        };

        return fetch(url, options);
      });
    },
    []
  );

  const handleError = useCallback(async (result: any) => {
    switch (result.status) {
      case 403:
      case 401:
        window.location.href = ApplicationPaths.Unauthorized;
        return;
      case 400:
      case 409:
      case 422:
      case 500:
        NotificationStore.boundActions.showErrorNotification();
        const response = await result.json();
        notification.error({
          message: "Server Error",
          description: JSON.stringify(response.errors),
        });
        return new Result(null, response);
      case 404:
        return new Result({});
      default:
        notification.error({
          message: "General Error",
          description: String(result),
        });
        throw result;
    }
  }, []);

  const getResultByResponseType = useCallback(
    async (response: Response, opts: IRequestOptions): Promise<Result<any>> => {
      switch (opts.responseType) {
        case "STRING":
          return new Result(await response.text());
        case "ARRAYBUFFER":
          return new Result(await response.arrayBuffer());
        case "BLOB":
          return new Result(await response.blob());
        case "FORMDATA":
          return new Result(await response.formData());
        case "ANY":
        default:
          return new Result(await response.json());
      }
    },
    []
  );

  const requestJsonFetch = useCallback(
    async <T>(opts: IRequestOptions): Promise<Result<T>> => {
      setLoading(true);
      const processQuery = (url: string, data: any): string => {
        if (data) {
          const args = new URLSearchParams(data);
          return `${url}?${args.toString()}`;
        }
        return url;
      };

      try {
        const result = await executeAuthenticatedRequest(
          processQuery(opts.url, opts.data),
          { method: opts.method }
        );
        if (!result.ok) {
          return await handleError(result);
        } else {
          return getResultByResponseType(result, opts);
        }
      } catch (error) {
        if (opts.onError) {
          try {
            return opts.onError(error);
          } catch {
            await handleError(error);
          }
        } else {
          await handleError(error);
        }
      } finally {
        setLoading(false);
      }
    },
    [executeAuthenticatedRequest, getResultByResponseType, handleError]
  );

  const sendData = useCallback(
    async <T>(
      opts: ISendFormDataOptions,
      payloadType?: PayloadTypes
    ): Promise<Result<T>> => {
      setLoading(true);
      const header: HeadersInit = {};

      switch (payloadType) {
        case PayloadTypes.Json:
          header["Content-Type"] = "application/json";
          header["Accept"] = "application/json";
          break;
        case PayloadTypes.MultipartFormData:
          break;
        case PayloadTypes.FormData:
        default:
          break;
      }

      try {
        const result = await executeAuthenticatedRequest(opts.url, {
          method: opts.method,
          headers: header,
          body: opts.data,
        });
        if (!result.ok) {
          return await handleError(result);
        } else {
          return getResultByResponseType(result, opts);
        }
      } catch (error) {
        await handleError(error);
      } finally {
        setLoading(false);
      }
    },
    [executeAuthenticatedRequest, getResultByResponseType, handleError]
  );

  return {
    loading,
    requestJsonFetch,
    executeAuthenticatedRequest,
    sendData,
  };
};
