// useRoomBookingsQueries.ts
import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryResult,
  UseMutationResult,
} from "@tanstack/react-query";

import { EntitiesList } from "../../models/Common/IEntitiesList";
import { IRoomBookingsItem } from "../../models/RoomBookings/Interfaces/IRoomBookingsItem";
import { AppSettings } from "../../utils/settings";
import { useServiceBase } from "../../services/useServiceBase";
import { IAntdSelectListItem } from "../../models/Common/IAntdSelectListItem";
import LookupService from "../../services/LookupService";

const API_URL = AppSettings.CommonSettings.ApiUrl;

export const useRoomBookingsQueries = () => {
  const { requestJsonFetch, loading: isFetchingLoading } = useServiceBase();
  const queryClient = useQueryClient();

  const getRoomBookings = (
    toTake: number,
    toSkip: number,
    filters: any
  ): UseQueryResult<EntitiesList<IRoomBookingsItem>, Error> => {
    return useQuery({
      queryKey: ["roomBookings", toTake, toSkip, filters],
      queryFn: async () => {
        let url = `${API_URL}/odata/room-bookings/`;
        if (filters) {
          url = `${url}${filters}`;
        }
        const result = await requestJsonFetch({
          method: "GET",
          url,
        });

        return result.value;
      },
    });
  };

  const acceptRoomBooking = (): UseMutationResult<any, Error, number> => {
    return useMutation({
      mutationFn: (id: number) =>
        requestJsonFetch({
          url: `${API_URL}/room-bookings/${id}/accept`,
          method: "PUT",
        }),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["roomBookings"] });
      },
    });
  };

  const rejectRoomBooking = (): UseMutationResult<
    any,
    Error,
    { id: number; reasonId: string }
  > => {
    return useMutation({
      mutationFn: ({ id, reasonId }) =>
        requestJsonFetch({
          method: "PUT",
          url: `${API_URL}/room-bookings/${id}/reject/${reasonId}`,
        }),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["roomBookings"] });
      },
    });
  };
  const getNumberOfPendingRoomRequests = (): UseQueryResult<number, Error> => {
    return useQuery({
      queryKey: ["pendingRoomRequests"],
      queryFn: () =>
        requestJsonFetch({
          url: `${API_URL}/room-bookings/pending/count`,
          method: "GET",
        }),
    });
  };

  const getGroupWorkspaces = (): UseQueryResult<
    IAntdSelectListItem[],
    Error
  > => {
    return useQuery({
      queryKey: ["groupWorkspaces"],
      queryFn: async () => {
        const result = await LookupService.requestJsonFetch<
          IAntdSelectListItem[]
        >({
          method: "GET",
          url: `${AppSettings.CommonSettings.ApiUrl}/lookup/workspaces`,
        });

        return result.value;
      },
    });
  };

  return {
    getRoomBookings,
    acceptRoomBooking,
    rejectRoomBooking,
    getNumberOfPendingRoomRequests,
    getGroupWorkspaces,
    isFetchingLoading,
  };
};
