import React, { useCallback } from "react";
import { String_Format } from "../../utils/utils";
import { v4 as uuidv4 } from "uuid";
import IPageIndex from "../../models/Pagination/IPageIndex";

interface IProps {
  gridTextItemResource?: any;
  totalItems: number;
  maxPagesDisplayed?: number;
  pageNeighbours?: number;
  firstIndexFromPage: number;
  lastIndexFromPage: number;
  pageIndexArray: IPageIndex[];
  reload: () => void;
  changeCurrentPage: (currentPage: number) => void;
}

const PaginationBar: React.FC<IProps> = ({
  gridTextItemResource,
  totalItems,
  maxPagesDisplayed = 7,
  pageNeighbours = 1,
  firstIndexFromPage,
  lastIndexFromPage,
  pageIndexArray,
  reload,
  changeCurrentPage,
}) => {
  const calculatePageIndexArrayRendered = useCallback(() => {
    const array = [];
    const totalPages = pageIndexArray.length;

    if (totalPages) {
      const currentPage =
        pageIndexArray.find((p) => p.isActive)?.pageNumber || 1;
      const elipsis = (
        <a
          onClick={(e) => e.preventDefault()}
          className="pagination-elipsis"
          href="#"
        >
          ...
        </a>
      );

      let leftSizeEllipsis =
        currentPage - pageNeighbours - pageIndexArray[0].pageNumber - 1;
      let rightSizeEllipsis =
        totalPages -
        currentPage -
        pageNeighbours -
        pageIndexArray[0].pageNumber;

      if (leftSizeEllipsis <= 0) {
        rightSizeEllipsis += leftSizeEllipsis - 1;
      }
      if (rightSizeEllipsis <= 0) {
        leftSizeEllipsis += rightSizeEllipsis - 1;
      }

      array.push(renderPageIndex(pageIndexArray[0]));
      if (leftSizeEllipsis >= 1) {
        array.push(<li key={uuidv4()}>{elipsis}</li>);
      }

      pageIndexArray.forEach((i) => {
        if (
          totalPages <= maxPagesDisplayed &&
          i.pageNumber !== 1 &&
          i.pageNumber !== totalPages
        ) {
          array.push(renderPageIndex(i));
        } else if (
          i.pageNumber !== 1 &&
          i.pageNumber !== totalPages &&
          i.pageNumber > 1 + leftSizeEllipsis &&
          i.pageNumber <= totalPages - rightSizeEllipsis - 1
        ) {
          array.push(renderPageIndex(i));
        }
      });

      if (rightSizeEllipsis >= 1) {
        array.push(<li key={uuidv4()}>{elipsis}</li>);
      }
      if (totalPages !== 1) {
        array.push(renderPageIndex(pageIndexArray[totalPages - 1]));
      }
    }

    return array;
  }, [pageIndexArray, pageNeighbours, maxPagesDisplayed]);

  const onPrevButtonClick = useCallback(
    (e: React.MouseEvent<HTMLLIElement>) => {
      e.preventDefault();
      const currentPage =
        pageIndexArray.find((p) => p.isActive)?.pageNumber || 1;
      if (currentPage > 1) {
        changeCurrentPage(currentPage - 1);
        reload();
      }
    },
    [pageIndexArray, changeCurrentPage, reload]
  );

  const onNextButtonClick = useCallback(
    (e: React.MouseEvent<HTMLLIElement>) => {
      e.preventDefault();
      const currentPage =
        pageIndexArray.find((p) => p.isActive)?.pageNumber || 1;
      if (currentPage < pageIndexArray.length) {
        changeCurrentPage(currentPage + 1);
        reload();
      }
    },
    [pageIndexArray, changeCurrentPage, reload]
  );

  const onPageIndexClick = useCallback(
    (e: React.MouseEvent<HTMLLIElement>) => {
      e.preventDefault();
      const pageIndexClicked =
        +e.currentTarget.getAttribute("data-page-number")!;
      if (!pageIndexArray[pageIndexClicked - 1].isActive) {
        changeCurrentPage(pageIndexClicked);
        reload();
      }
    },
    [pageIndexArray, changeCurrentPage, reload]
  );

  const renderPageIndex = (model: IPageIndex) => (
    <li
      key={uuidv4()}
      className={model.isActive ? "active" : ""}
      data-page-number={model.pageNumber}
      onClick={onPageIndexClick}
    >
      {model.isActive ? (
        <span>{model.pageNumber}</span>
      ) : (
        <a href="#">{model.pageNumber}</a>
      )}
    </li>
  );

  const currentPage = pageIndexArray.find((p) => p.isActive)?.pageNumber || 1;
  const totalPages = pageIndexArray.length;

  return totalPages > 0 ? (
    <div className="pagination">
      <div className="items-info">
        {gridTextItemResource
          ? String_Format(
              gridTextItemResource,
              totalItems === 0 ? 0 : firstIndexFromPage,
              lastIndexFromPage,
              totalItems
            )
          : String_Format(
              "Showing {0} - {1} out of {2} items",
              totalItems === 0 ? 0 : firstIndexFromPage,
              lastIndexFromPage,
              totalItems
            )}
      </div>

      <div>
        <ul className="pager">
          <li
            onClick={onPrevButtonClick}
            className={`prev ${
              currentPage === 1 || totalItems === 0 ? "disabled" : ""
            }`}
          >
            <a href="#">
              <span className="material-symbols-outlined">chevron_left</span>
            </a>
          </li>

          {calculatePageIndexArrayRendered()}

          <li
            onClick={onNextButtonClick}
            className={`next ${
              currentPage === totalPages || totalItems === 0 ? "disabled" : ""
            }`}
          >
            <a href="#">
              <span className="material-symbols-outlined">chevron_right</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  ) : null;
};

export default PaginationBar;
