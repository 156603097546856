import { useCallback, useEffect, useMemo } from "react";
import { useQueryClient, UseQueryResult } from "@tanstack/react-query";
import buildQuery from "odata-query";

import { useRoomBookingsQueries } from "./useRoomBookingsQueries";
import { firstLetterToLowerCase } from "../../utils/utils";
import { RoomBookingStatuses } from "../../enums/RoomBookings/RoomBookingStatuses";
import RoomBookingStatusTypes from "../../resources/AdminUI/RoomBookings/RoomBookingStatusTypes";
import { IRoomBookingsItem } from "../../models/RoomBookings/Interfaces/IRoomBookingsItem";
import { IEntitiesList } from "../../models/Common/IEntitiesList";
import { usePendingBookingsContext } from "../../providers/PendingBookingsProvider";

export const usePendingRoomsRequestsPage = (statusId?: string) => {
  const {
    itemsPerPage,
    currentPage,
    filters,
    numberOfRoomBookings,
    setFilters,
    setLoading,
    setRoomBookings,
    setNumberOfRoomBookings,
    setCurrentPage,
    setPageIndexArray,
    setFirstIndexFromPage,
    setLastIndexFromPage,
  } = usePendingBookingsContext();

  const { getRoomBookings } = useRoomBookingsQueries();
  const queryClient = useQueryClient();
  const skip = useMemo(
    () => (currentPage - 1) * itemsPerPage,
    [currentPage, itemsPerPage]
  );

  const {
    data,
    isLoading,
    error,
  }: UseQueryResult<IEntitiesList<IRoomBookingsItem>, Error> = getRoomBookings(
    itemsPerPage,
    skip,
    filters
  );

  useEffect(() => {
    setCurrentPage(currentPage);
  }, []);

  useEffect(() => {
    if (data) {
      setRoomBookings(data.entities);
      setNumberOfRoomBookings(data.numberOfEntities);
    } else {
      setRoomBookings([]);
      setNumberOfRoomBookings(0);
    }
    setLoading(false);
  }, [data]);

  useEffect(() => {
    recalculatePageArray();
    recalculateIndexes();
  }, [numberOfRoomBookings, currentPage]);

  const changeCurrentPage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const recalculatePageArray = useCallback(() => {
    const totalPages =
      Math.floor((numberOfRoomBookings - 1) / itemsPerPage) + 1;
    const pageArray = [];
    for (let i = 1; i <= totalPages; i++) {
      pageArray.push({ pageNumber: i, isActive: currentPage === i });
    }
    setPageIndexArray(pageArray);
  }, [currentPage, numberOfRoomBookings]);

  const recalculateIndexes = useCallback(() => {
    const firstIndex = (currentPage - 1) * itemsPerPage + 1;
    const totalPages =
      Math.floor((numberOfRoomBookings - 1) / itemsPerPage) + 1;
    const lastIndex =
      currentPage === totalPages
        ? Math.min(numberOfRoomBookings, itemsPerPage * totalPages)
        : currentPage * itemsPerPage;
    setFirstIndexFromPage(firstIndex);
    setLastIndexFromPage(lastIndex);
  }, [currentPage, numberOfRoomBookings]);

  const getAllRoomStatuses = () => {
    let options = [];
    let keys = Object.keys(RoomBookingStatuses).filter(
      (k) => typeof RoomBookingStatuses[k as any] === "number"
    );

    keys.forEach((key) => {
      let value = RoomBookingStatuses[key as any].toString();
      options.push({
        value: value,
        label: RoomBookingStatusTypes.Resources[firstLetterToLowerCase(key)],
      });
    });
    return options;
  };

  const rebuildODataFilters = (toTake: number, toSkip: number): string => {
    const filters: any = {};

    // Add StatusId filter
    if (statusId) {
      filters.StatusId = { eq: parseInt(statusId) };
    }

    // Build the query string with $top, $skip, $orderby, and $count
    const query = buildQuery({
      filter: filters,
      top: toTake, // Add $top parameter
      skip: toSkip, // Add $skip parameter
      orderBy: "Id desc", // Add $orderby parameter
      count: true, // Add $count parameter
    });

    return query; // Returns the full OData query string with filters and additional parameters
  };

  const oDataFilter = useMemo(
    () => rebuildODataFilters(itemsPerPage, skip),
    [itemsPerPage, skip]
  );

  useEffect(() => {
    setFilters(oDataFilter);
  }, [oDataFilter]);

  const reloadRoomBookingsData = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ["roomBookings"] });
  }, [queryClient]);

  return {
    filters: oDataFilter,
    skip,
    isLoading,
    error,
    rebuildODataFilters,
    changeCurrentPage,
    getAllRoomStatuses,
    recalculateIndexes,
    recalculatePageArray,
    reloadRoomBookingsData,
  };
};
