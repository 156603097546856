import { RoomBookingTypes } from "../../enums/RoomBookings/RoomBookingTypes";
import General from "../../resources/AdminUI/General";
import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import RoomBookingTypeList from "../../resources/AdminUI/RoomBookings/RoomBookingTypeList";
import {
  formatDateInZone,
  isSameDay,
  DATE_FORMAT,
  formatDate,
} from "../../utils/date";
import { firstLetterToLowerCase, String_Format } from "../../utils/utils";
import ModalDialog from "../Shared/ModalDialog";
import DeclineRoomBookingConfirmationModal from "./DeclineRoomBookingConfirmationModal";

interface IProps {
  isDeclineModalOpen: boolean;
  selectedRoomBooking: any;
  showDeclineModal: (roomBooking: any) => void;
  hideDeclineModal: () => void;
  getDeclineReasonsList: () => any;
  showAcceptRoomBookingModal: (id: number) => void;
}

export const getPendingRoomBookingsColumns = (props: IProps) => {
  const buildBookingInfoCell = (row) => {
    const { bookingTypeId, startsAt, endsAt, timezone, bookingAmount } =
      row.original;
    let type = RoomBookingTypes[bookingTypeId];
    let typeName = RoomBookingTypeList.Resources[firstLetterToLowerCase(type)];

    switch (bookingTypeId) {
      case RoomBookingTypes.Hourly:
        return (
          <div className="column-xlarge">
            <p>{typeName}</p>
            <p className="timestamp">
              {formatDateInZone(startsAt, timezone)}-{" "}
              {formatDateInZone(endsAt, timezone)}
              <span className="timezone"> {timezone}</span>
            </p>
          </div>
        );
      case RoomBookingTypes.Daily:
        return (
          <div className="column-xlarge">
            <p>
              {String_Format(
                RoomBookingsGrid.Resources.bookingAmount,
                typeName,
                bookingAmount
              )}
            </p>
            <p className="timestamp">
              {isSameDay(startsAt, endsAt)
                ? formatDateInZone(startsAt, timezone, DATE_FORMAT)
                : formatDateInZone(startsAt, timezone, DATE_FORMAT) +
                  " - " +
                  formatDateInZone(endsAt, timezone, DATE_FORMAT)}
              <span className="timezone"> {timezone}</span>
            </p>
          </div>
        );
      case RoomBookingTypes.Monthly:
        return (
          <div className="column-xlarge">
            <p>
              {String_Format(
                RoomBookingsGrid.Resources.bookingAmount,
                typeName,
                bookingAmount * 30
              )}
            </p>
            <p className="timestamp">
              {formatDateInZone(startsAt, timezone, DATE_FORMAT)}-{" "}
              {formatDateInZone(endsAt, timezone, DATE_FORMAT)}
              <span className="timezone"> {timezone}</span>
            </p>
          </div>
        );
    }
  };

  return [
    {
      header: "ACTIONS",
      cell: ({ row }) => (
        <div className="btn-row-actions">
          <div
            className="btn-big btn-secondary"
            onClick={() => props.showDeclineModal(row.original)}
          >
            {General.Resources.decline}
          </div>
          <ModalDialog
            title={RoomBookingsGrid.Resources.declineRoomRequest}
            open={
              props.isDeclineModalOpen &&
              props.selectedRoomBooking == row.original
            }
            onCancel={props.hideDeclineModal}
          >
            <DeclineRoomBookingConfirmationModal
              onCancelCallback={props.hideDeclineModal}
              id={row.original.id}
              reasons={props.getDeclineReasonsList()}
            />
          </ModalDialog>

          <div
            className="btn-big btn-primary"
            onClick={() => props.showAcceptRoomBookingModal(row.original.id)}
          >
            {General.Resources.accept}
          </div>
        </div>
      ),
    },
    {
      header: "VISIT ID",
      cell: ({ row }) => (
        <div className="column-xsmall">
          <p>{String_Format(RoomBookingsGrid.Resources.id, row.original.id)}</p>
        </div>
      ),
    },

    {
      header: "SPACE",
      cell: ({ row }) => (
        <div>
          <p className="room-name">{row.original.workspaceName}</p>
          <p>
            {String_Format(
              RoomBookingsGrid.Resources.spaceId,
              row.original.workspaceId
            )}
          </p>
        </div>
      ),
    },
    {
      header: "ROOM",
      cell: ({ row }) => (
        <div className="column-medium">
          <p className="room-name">{row.original.roomName}</p>
          <p>
            {String_Format(
              RoomBookingsGrid.Resources.roomId,
              row.original.roomId
            )}
          </p>
        </div>
      ),
    },
    {
      header: "BOOKING INFO",
      cell: ({ row }) => buildBookingInfoCell(row),
    },
    {
      header: "REQUEST TIMESTAMP",
      cell: ({ row }) => (
        <div className="column-medium">
          <p className="timestamp">{formatDate(row.original.createdAt)}</p>
        </div>
      ),
    },
  ];
};
