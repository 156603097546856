import * as React from "react";
import { Routes, Route } from "react-router";
import { Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { AuthorizedLayoutComponent } from "./layouts/Layout";
import { ApplicationPaths } from "./enums/Common/ApplicationPaths";
import NotFoundPage from "./pages/NotFoundPage";
import HotdeskBookingsGridPage from "./pages/HotdeskBookings/HotdeskBookingsGridPage";
import RoomBookingsGridPage from "./pages/RoomBookings/RoomBookingsGridPage";
import PendingRoomRequestsPage from "./pages/RoomBookings/PendingRoomRequestsPage";
import DashboardPage from "./pages/Dashboard/DashboardPage";
import UnauthorizedPage from "./pages/UnauthorizedPage";
import { PendingBookingsProvider } from "./providers/PendingBookingsProvider";
import { RoomBookingsProvider } from "./providers/RoomBookingsProvider";

const RouteList = () => {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 60 * 1000, // 1 minute
          },
        },
      })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <RoomBookingsProvider>
        <PendingBookingsProvider>
          <Routes>
            <Route
              path={ApplicationPaths.EmptyPath}
              element={<Navigate to={ApplicationPaths.Dashboard} replace />}
            />
            ;
            <Route
              path={ApplicationPaths.Unauthorized}
              element={<UnauthorizedPage />}
            />
            <Route
              path={ApplicationPaths.Dashboard}
              element={
                <AuthorizedLayoutComponent>
                  <DashboardPage />
                </AuthorizedLayoutComponent>
              }
            />
            <Route
              path={ApplicationPaths.DesksLogs}
              element={
                <AuthorizedLayoutComponent>
                  <HotdeskBookingsGridPage />
                </AuthorizedLayoutComponent>
              }
            />
            <Route
              path={ApplicationPaths.RoomsLogs}
              element={
                <AuthorizedLayoutComponent>
                  <RoomBookingsGridPage />
                </AuthorizedLayoutComponent>
              }
            />
            <Route
              path={ApplicationPaths.PendingRoomRequests}
              element={
                <AuthorizedLayoutComponent>
                  <PendingRoomRequestsPage />
                </AuthorizedLayoutComponent>
              }
            />
            <Route
              path={ApplicationPaths.NotFound}
              element={
                <AuthorizedLayoutComponent>
                  <NotFoundPage />
                </AuthorizedLayoutComponent>
              }
            />
            <Route
              path="*"
              element={
                <AuthorizedLayoutComponent>
                  <NotFoundPage />
                </AuthorizedLayoutComponent>
              }
            />
          </Routes>
        </PendingBookingsProvider>
      </RoomBookingsProvider>
    </QueryClientProvider>
  );
};

export default RouteList;
