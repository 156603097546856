import React from "react";
import Helmet from "react-helmet";
import { Dayjs } from "dayjs";

import RoomBookingsGrid from "../../resources/AdminUI/RoomBookings/RoomBookingsGrid";
import { withRouter } from "../../components/Shared/withRouter";
import FiltersSection from "../../components/RoomBookings/FiltersSection";
import { useRoomBookingsContext } from "../../providers/RoomBookingsProvider";
import { useRoomBookingsPage } from "../../hooks/RoomBookings/useRoomBookingsPage";
import RoomBookingsGridWrapper from "../../components/RoomBookings/RoomBookingsGridWrapper";
import { getRoomBookingsColumns } from "../../components/RoomBookings/RoomBookingsTableColumns";

const RoomBookingsGridPage = () => {
  const {
    searchTerm,
    singleSelectedStatus,
    singleSelectedWorkspace,
    startDateSearchTemplate,
    endDateSearchTemplate,
    setSearchTerm,
    setSingleSelectedStatus,
    setSingleSelectedWorkspace,
    setDateRange,
  } = useRoomBookingsContext();

  const { workspacesData, getAllRoomStatuses } = useRoomBookingsPage();

  return (
    <div>
      <Helmet title={RoomBookingsGrid.Resources.roomsLogs} />
      <div className="page-top-container">
        <div className="page-title">{RoomBookingsGrid.Resources.roomsLogs}</div>
      </div>
      <FiltersSection
        workspaces={workspacesData}
        singleSelectedWorkspace={singleSelectedWorkspace}
        singleSelectedStatus={singleSelectedStatus}
        searchTerm={searchTerm}
        startDateSearchTemplate={startDateSearchTemplate}
        endDateSearchTemplate={endDateSearchTemplate}
        dateFormat={"DD-MM-YYYY"}
        onSearchFilterChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
          setSearchTerm(e.target.value)
        }
        onWorkspaceFilterChange={(value: string): void =>
          setSingleSelectedWorkspace(value)
        }
        setSingleSelectedStatus={setSingleSelectedStatus}
        onDateChange={(dates: [Dayjs | null, Dayjs | null]): void => {
          if (!dates) {
            setDateRange(null, null);
            return;
          }
          setDateRange(dates[0]?.unix(), dates[1]?.unix());
        }}
        getAllRoomStatuses={getAllRoomStatuses}
      />

      <RoomBookingsGridWrapper
        roomBookingsColumns={getRoomBookingsColumns(true)}
      />
    </div>
  );
};

const componentId = "RoomBookingGridPage";

export default withRouter(RoomBookingsGridPage);
